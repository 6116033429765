import { RootStateOrAny, useSelector } from 'react-redux';
import { Col, Grid, Row, Space, Typography } from 'antd';

import ImageC from 'src/components/Image';
import { Title } from 'src/components/Layout/Typography';
import ModalC from 'src/components/Modal';
import { MenuProps } from 'src/interface';

import classes from './MainMenuModal.module.less';

type Props = {
	showModal: boolean;
	setShowModal: (v: any) => void;
	handleClickMenu: (e: any, name: string, menuData: MenuProps) => void;
};

const MainMenuModal = (props: Props) => {
	const { showModal, setShowModal, handleClickMenu } = props;
	const { xs } = Grid.useBreakpoint();
	const { menu } = useSelector((state: RootStateOrAny) => state);

	const serviceList = menu?.data?.record?.pilihan_menu || [];
	const healthTools = menu?.data?.record?.deteksi_menu || [];

	const MainMenuList = ({ list }: any) => {
		return (
			<Row gutter={[10, { xs: 20, sm: 24 }]}>
				{list?.map((item: MenuProps, i: number) => (
					<Col xs={6} sm={4} key={i}>
						<a
							href={item.deeplink}
							className="cursor-pointer"
							onClick={(e: any) => handleClickMenu(e, item.title, item)}
						>
							<>
								<div className="d-flex justify-content-center text-center">
									<ImageC
										width={xs ? 57 : 72}
										height={xs ? 57 : 72}
										src={item.icon}
										objectFit="cover"
									/>
								</div>
								<div className="text-center">
									<Title level={3} text={item.title.replace(' ', '\n')} className={classes.title} />
								</div>
							</>
						</a>
					</Col>
				))}
			</Row>
		);
	};

	return (
		<ModalC
			width={600}
			visible={showModal}
			hideButton={true}
			onCancel={() => setShowModal(false)}
			closable
		>
			<div className={classes.content}>
				<Space size={xs ? 20 : 32} direction="vertical" className={classes.space}>
					<div>
						<Typography.Title level={4} className={classes.category}>
							Pilihan Kamu
						</Typography.Title>

						<MainMenuList list={serviceList} />
					</div>

					<div>
						<Typography.Title level={4} className={classes.category}>
							Cek Kesehatanmu
						</Typography.Title>
						<MainMenuList list={healthTools} />
					</div>
				</Space>
			</div>
		</ModalC>
	);
};

export default MainMenuModal;
