import { useState } from 'react';
import { Col, Grid, Row } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import { Title } from 'src/components/Layout/Typography';
import { MenuProps } from 'src/interface';

import ComingSoonModal from './ComingSoonModal';
import MainMenuModal from './MainMenuModal';

import classes from './index.module.less';

interface MainMenuProps {
	items: MenuProps[];
}

const MainSectionC = ({ items }: MainMenuProps) => {
	const { xs, sm, md, lg } = Grid.useBreakpoint();
	const [showMainMenuModal, setMainMenuModal] = useState(false);

	const [showComingSoonModal, setShowComingSoonModal] = useState(false);

	const [comingSoonData, setComingSoonData] = useState<
		MenuProps['extra_description'] | undefined
	>();
	const patternValidUrl = new RegExp(/^(http(s?)):\/\//i);
	const responsive = (xs || sm) && !md;
	const imgSizeXl = lg ? 80 : 72;
	const imgSize = xs ? 64 : imgSizeXl;

	const handleClick = (e: any, name: string, menuData: MenuProps) => {
		if (name === 'Lainnya') {
			e.preventDefault();
			setMainMenuModal(true);
		}

		if (menuData.is_coming_soon) {
			e.preventDefault();
			setComingSoonData(menuData.extra_description);
			setShowComingSoonModal(true);
		}
	};

	const IconC = (item: MenuProps) => {
		return (
			<>
				<div className="d-flex justify-content-center text-center">
					<ImageC width={imgSize} height={imgSize} src={item.icon} objectFit="cover" />
				</div>
				<div className="text-center">
					<Title level={3} text={item.title.replace(' ', '\n')} className={classes.title} />
				</div>
			</>
		);
	};

	const handleCloseComingSoonModal = () => {
		setComingSoonData(undefined);
		setShowComingSoonModal(false);
	};

	return (
		<div className={classNames(classes.wrapper, { container: !lg })}>
			{lg && (
				<>
					<div className={classes['bg-left']}>
						<ImageC width={176} height={109} src="/icons/home/bg-home-left.svg" />
					</div>
					<div className={classes['bg-right']}>
						<ImageC width={203} height={161} src="/icons/home/bg-home-right.svg" />
					</div>
				</>
			)}

			<Row
				justify={xs ? 'start' : 'center'}
				gutter={[{ xs: 0, sm: 16, lg: 40 }, 24]}
				id="cy-main-menu"
			>
				{items.map((item: MenuProps, i: number) => (
					<Col className={classes.box} flex={responsive ? '0 0 25%' : 'none'} key={i}>
						{patternValidUrl.test(item.deeplink) && !item.deeplink.includes('/kalstore') ? (
							<a
								href={item.deeplink}
								className="cursor-pointer"
								onClick={(e: any) => handleClick(e, item.title, item)}
								rel="nofollow"
							>
								<IconC {...item} />
							</a>
						) : (
							<a
								href={item.deeplink}
								className="cursor-pointer"
								onClick={(e: any) => handleClick(e, item.title, item)}
							>
								<IconC {...item} />
							</a>
						)}
					</Col>
				))}
			</Row>

			<MainMenuModal
				showModal={showMainMenuModal}
				setShowModal={setMainMenuModal}
				handleClickMenu={handleClick}
			/>
			<ComingSoonModal
				visible={showComingSoonModal}
				data={comingSoonData}
				onCancel={handleCloseComingSoonModal}
			/>
		</div>
	);
};

export default MainSectionC;
